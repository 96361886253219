import { KBSuspenseLoading } from '@/components/atoms/KBSuspenseLoading'
import routes from '@/routes'
import { useReactQueryClient } from '@/utils/react-query-client'
import NiceModal from '@ebay/nice-modal-react'
import { wrapUseRoutes } from '@sentry/react'
import { QueryClientProvider } from '@tanstack/react-query'
import { I18nextPlugin, Tolgee, withTolgee } from '@tolgee/i18next'
import { useTitle } from 'ahooks'
import { App as AntdApp } from 'antd'
import i18n from 'i18next'
import ICU from 'i18next-icu'
import { Suspense } from 'react'
import { initReactI18next } from 'react-i18next'
const KBNoticeBillingModal = lazy(
  () =>
    import('@/components/organisms/KBNoticeBillingModal/KBNoticeBillingModal')
)
const NewVersionModal = lazy(
  () => import('@/components/layouts/components/NewVersionModal')
)
const KBMessageGlobal = lazy(() => import('@/components/atoms/KBMessageGlobal'))
const KBMapLocationModal = lazy(
  () => import('@/components/organisms/KBMapLocationModal/KBMapLocationModal')
)

import { dateFormatEffect, themeAtom } from '@/store'
import { darkTheme } from '@/themes/antd/darkTheme'
import { lightTheme } from '@/themes/antd/lightTheme'
import { setUseWhatChange } from '@simbathesailor/use-what-changed'
import { AnimatePresence } from 'framer-motion'
import i18next from 'i18next'
import { useAtom } from 'jotai'
import { LocaleProvider } from './contexts/app_context'

const useSentryRoutes = wrapUseRoutes(useRoutes)
function RouteContent() {
  return <>{useSentryRoutes(routes)}</>
}

setUseWhatChange(import.meta.env.VITE_NODE_ENV === 'local')

const isProduction = import.meta.env.VITE_NODE_ENV === 'production'

const initTolgee = async () => {
  const tolgeeInstance = Tolgee()

  if (!isProduction) {
    // FIXME: Production 环境需要去掉 InContextTools
    const { InContextTools } = await import('@tolgee/web/tools')
    tolgeeInstance.use(InContextTools())

    return tolgeeInstance.use(I18nextPlugin()).init({
      language: 'en-US',
      defaultLanguage: 'en-US',
      apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
      apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,
    })
  }

  return tolgeeInstance.use(I18nextPlugin()).init({
    language: 'en-US',
    defaultLanguage: 'en-US',
  })
}

// Initialize tolgee
const tolgee = await initTolgee()

withTolgee(i18n, tolgee)
  .use(ICU)
  .use(initReactI18next)
  // .use(LanguageDetector) // 使用语言检测插件
  .init({
    lng: 'en-US', // 默认语言
    fallbackLng: 'en-US', // 后备语言
    supportedLngs: ['en-US', 'zh-CN', 'zh-TW', 'pt-BR', 'es-BR', 'es-LA'],
    partialBundledLanguages: true,
    resources: isProduction
      ? {
          'en-US': {
            translation: await import('./locales/translation/en-US.json').then(
              (m) => m.default
            ),
          },
        }
      : undefined,
    backend: isProduction
      ? {
          loadPath: './locales/translation/{{lng}}.json',
        }
      : undefined,
  })

i18next.on('languageChanged', async (lng) => {
  if (isProduction && lng !== 'en-US') {
    const translations = await import(`./locales/translation/${lng}.json`).then(
      (m) => m.default
    )
    i18next.addResourceBundle(lng, 'translation', translations, true, true)
  } else if (lng === 'es-LA' && !isProduction) {
    // 在生产环境中，如果 localeName 是 'es-LA'，则将语言设置为 'es-BR'
    i18next.changeLanguage('es-BR')
  }
})

console.log('>>>i18n language', i18next.language)

const App = () => {
  const queryClientRef = useReactQueryClient()
  const [theme] = useAtom(themeAtom)
  useAtom(dateFormatEffect)

  useTitle(import.meta.env.VITE_BRAND)

  return (
    <AntdApp>
      <Suspense fallback={<KBSuspenseLoading fullScreen={true} />}>
        <QueryClientProvider client={queryClientRef.current}>
          <LocaleProvider
            theme={{
              cssVar: {
                prefix: 'wox',
              },
              hashed: false,
              ...(theme === 'dark' ? darkTheme : lightTheme),
            }}
          >
            <NiceModal.Provider>
              {/* 费用中心权限的提示弹框 */}
              <Suspense fallback={null}>
                <KBNoticeBillingModal id="KBNoticeBillingModal" />
                <NewVersionModal />
                <KBMessageGlobal />
                <KBMapLocationModal id="KBMapLocationModal" />
              </Suspense>
              <AnimatePresence>
                <RouteContent />
              </AnimatePresence>
            </NiceModal.Provider>
            {/* {isDevEnv && <ReactQueryDevtools initialIsOpen={false} />} */}
          </LocaleProvider>
        </QueryClientProvider>
      </Suspense>
    </AntdApp>
  )
}

export default App
