import { KBCol, KBTypography } from '@/components/atoms'

const { Text } = KBTypography

export interface KBDetailsItemsProps {
  title: string
  children?: React.ReactNode
  colSpan?: number
}

export function KBDetailsItems({
  title,
  children,
  colSpan = 12,
}: KBDetailsItemsProps) {
  return (
    <KBCol span={colSpan}>
      <Text type="secondary" className="tw-mb-1.5 tw-block tw-text-sm">
        {title}
      </Text>
      <Text className="tw-block tw-text-sm tw-font-medium">{children}</Text>
    </KBCol>
  )
}
