import {
  ResourceEntity,
  useResourceReservationAdminUpdate,
  useResourceReservationUpdate,
} from '@/api'
import { KBModal, KBSpace, KBText } from '@/components/atoms'
import { kbMessage, kbNotification } from '@/components/atoms/KBMessageGlobal'
import { KBDetailsItems } from '@/components/organisms/KBDetailsItems'
import { isUserClient } from '@/utils/userEnvironment'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Dayjs, formatDateTimeRange } from '@wox/dayjs'
import { t } from 'i18next'
interface EditReservationResourceModalProps {
  id: number
  resource: ResourceEntity
  startAt: Dayjs
  endAt: Dayjs
}

function EditReservationResourceModal(
  props: EditReservationResourceModalProps
) {
  const { id, resource, startAt, endAt } = props
  const modal = useModal()

  const editApi = isUserClient()
    ? useResourceReservationUpdate
    : useResourceReservationAdminUpdate

  const { mutate: updateResourceReservation, isPending: updating } = editApi({
    mutation: {
      onSuccess: () => {
        kbMessage.success(t('common.updateSuccess'))
        modal.resolve()
        modal.hide()
      },
      onError(e) {
        console.log('error---', e)
        modal.hide()
        if (e.response?.data?.errorCode === 422422) {
          const errorMessage = e.response?.data?.message
          if (errorMessage) {
            kbNotification.error(errorMessage, {
              id: errorMessage,
            })
          }
        }
      },
    },
  })

  const onSubmit = () => {
    updateResourceReservation({
      id,
      data: {
        resource_id: resource.id,
        start_at: startAt.format(),
        end_at: endAt.format(),
      },
    })
  }

  return (
    <KBModal
      modal={modal}
      title={t('reservation.editResource')}
      width={384}
      onOk={onSubmit}
      okButtonProps={{
        loading: updating,
      }}
    >
      <KBSpace size={24} direction="vertical">
        <KBText weight="bold" level={2}>
          {t('reservation.editResourceTarget')}
        </KBText>
        <KBDetailsItems
          colSpan={24}
          title={t('resource.name')}
          children={<KBText weight="middle">{resource.name}</KBText>}
        />
        <KBDetailsItems
          colSpan={24}
          title={t('reservation.reservationTime')}
          children={
            <KBText weight="middle">
              {formatDateTimeRange([startAt, endAt])}
            </KBText>
          }
        />
      </KBSpace>
    </KBModal>
  )
}

export default NiceModal.create(EditReservationResourceModal)
