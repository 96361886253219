import { useHover } from 'ahooks'
import { FormInstance } from 'antd'
import { motion } from 'framer-motion'
import React, { useRef } from 'react'

import { AuthUserEntity } from '@/api'
import { KBSpace } from '@/components/atoms'
import SpaceItem from '@/pages/auth/components/SpaceItem'

interface selectSpaceItemProp {
  user: AuthUserEntity
  onSelect: () => void
}
const SelectSpaceItem: React.FC<selectSpaceItemProp> = ({ user, onSelect }) => {
  const ref = useRef(null)
  const isHovering = useHover(ref)

  return (
    <KBSpace
      direction="vertical"
      className="test-space-item tw-w-full tw-cursor-pointer tw-rounded-md tw-px-[10px] tw-py-3 hover:tw-bg-[var(--wox-color-bg-text-hover)]"
      ref={ref}
    >
      <SpaceItem user={user} onSelect={onSelect} isHovering={isHovering} />
    </KBSpace>
  )
}
interface selectSpaceProp {
  form: FormInstance
  authUsers: AuthUserEntity[]
  onSelectAuthUser: (authUser: AuthUserEntity) => void
}
const SelectSpace: React.FC<selectSpaceProp> = ({
  form,
  authUsers,
  onSelectAuthUser,
}) => {
  return (
    <motion.div
      layout
      transition={{
        type: 'spring',
        stiffness: 700,
        damping: 30,
      }}
    >
      {authUsers?.map((space) => (
        <SelectSpaceItem
          key={space?.id}
          user={space}
          onSelect={() => onSelectAuthUser(space)}
        />
      ))}
    </motion.div>
  )
}

export default SelectSpace
